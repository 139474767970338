
body {
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  margin: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
