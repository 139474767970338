.NavbarItems {
    background: linear-gradient(90deg, rgb(20,43,66) 0%, rgba(39,85,130) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo {
    color: white;
    justify-content: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.navbar-logo:hover {
    background-color: rgb(62,135,207);
    border-radius: 6px;
    transition: all 0.4s ease-out;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: denter;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: rgb(62,135,207);
    border-radius: 4px;
    transition: all 0.2s ease-out;
}